import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";

import s from "./AppLayout.css";
import { Header, Link } from "../../components";
import { Button, Layout, Menu, Icon, Tooltip } from "antd";
import antStyles from "antd/dist/antd.less";
import globalStyles from "../../global.css";
const { SubMenu } = Menu;
const { Sider } = Layout;
import { HOME_ROUTE } from "../../routes";
import history from "../../history";
import paginationStyles from "react-horizontal-scrolling-menu/dist/styles.css";
import { handleStripePortalRedirect } from "../../reducers/user";
import { SIDER_WIDTH, AROMATHERAPY, AI_ASSISTANT, COSMETIC, DOCPARSE, ALCHEMY, LIFETIME } from "../../constants";
import { hasFeature } from "../../utils";
import { clearForms } from "../../reducers/product";
import BuyAssistant from "./BuyAssistant";
const regex_cosmode = /cosmode/;

class AppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      showSider: false,
      mounted: false,
      isTooltipVisible: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.iframeRef = React.createRef(); // Add this line
  }

  componentDidMount() {
    window.addEventListener("message", this.handleYouTubeMessage);
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateWindowDimensions();
    this.setState({
      mounted: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleYouTubeMessage);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  handleYouTubeMessage = (event) => {
    if (event.origin !== "https://www.youtube.com") return;

    if (event.data && typeof event.data === "string") {
      try {
        const data = JSON.parse(event.data);
        if (data.event === "onStateChange" && data.info === 0) {
          // Video has ended, you can handle this if needed
        }
      } catch (error) {
        console.error("Error parsing YouTube message:", error);
      }
    }
  };

  handleTooltipVisibilityChange = (visible) => {
    this.setState({ isTooltipVisible: visible }, () => {
      this.controlYouTubeVideo();
    });
  };

  controlYouTubeVideo = () => {
    try {
      const iframe = this.iframeRef.current;
      if (iframe) {
        const contentWindow = iframe.contentWindow;
        if (this.state.isTooltipVisible) {
          contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
        } else {
          contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', "*");
        }
      }
    } catch (error) {
      console.error("Error in controlYouTubeVideo:", error);
    }
  };

  // controlYouTubeVideo = () => {
  //   try {
  //     if (this.iframeRef.current) {
  //       const contentWindow = this.iframeRef.current.contentWindow;
  //       if (this.state.isTooltipVisible) {
  //         contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
  //       } else {
  //         contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error controlling YouTube video:", error);
  //   }
  // };

  manageSubscription = () => {
    this.props.handleStripePortalRedirect();
  };

  updateWindowDimensions = (event) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  getLogo = (sider_width) => {
    const logoimg = (
      <img className={s.logoimg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/bplogo.svg" alt={"logo"} loading="lazy" />
    );

    if (sider_width == 0) {
      return <span onClick={this.toggle}>{logoimg}</span>;
    } else if (this.props.currentRouteName === HOME_ROUTE) {
      return logoimg;
    } else {
      return <Link to={HOME_ROUTE}>{logoimg}</Link>;
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  handleClickOutside = async (event) => {
    if (this.node.contains(event.target)) {
      return;
    }

    await this.setState({ collapsed: true });
  };

  static defaultProps = {
    footer: true,
  };

  siderLeave = () => {
    this.setState({
      collapsed: true,
    });
  };

  siderEnter = () => {
    this.setState({
      collapsed: false,
    });
  };

  render() {
    const { hideSider, footer, currencies, currentRouteName } = this.props;

    const cosmode = process.env.BROWSER && regex_cosmode.test(history.location.pathname);
    const user = this.props.user;
    const sider_width = this.state.windowWidth && this.state.windowWidth > 880 ? SIDER_WIDTH : 0;
    // mobileForNav does not exist
    const sider_class = sider_width === 0 ? s.mobileForNav : s.forNav;
    const content_class = hideSider ? s.nosiderContent : s.content;

    const handleLogout = () => {
      var widget = document.getElementById("searchie-fixed-btn");
      if (widget) widget.parentNode.removeChild(widget);
      var script = document.getElementById("widget_script-fixed-btn");
      if (script) script.parentNode.removeChild(script);
    };

    const showAlchemy = hasFeature(user, ALCHEMY) || hasFeature(user, LIFETIME);
    const showCosmeticItem = hasFeature(user, COSMETIC);      
    const showAromatherapyMenu = hasFeature(user, AROMATHERAPY);
    const showAIItem = hasFeature(user, AI_ASSISTANT);

    return (
      <Layout className={s.container}>
        {this.state.mounted && currencies && (
          <React.Fragment>
            <div>
              {!hideSider && this.props.user != null && (
                <React.Fragment>
                  <div className={s.leftmenu} ref={(node) => (this.node = node)}>
                    <div className={s.topleftnav}>
                      <div className={s.logo}>{this.getLogo(sider_width)}</div>
                    </div>
                    {this.props.user != null && (
                      <div className={sider_class}>
                        <Sider
                          className={s.sider}
                          collapsible
                          collapsedWidth={sider_width}
                          collapsed={this.state.collapsed}
                          onMouseLeave={this.siderLeave}
                          onMouseEnter={this.siderEnter}
                          trigger={null}
                        >
                          {showAromatherapyMenu && (
                            <>
                              <Menu mode="inline" className={s.menuMobileTop}>
                                <Menu.Item className={s.ant_menu_horizontal} key="home" onClick={() => history.push("/")}>
                                  <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/home.svg" />
                                  <span>Home</span>
                                </Menu.Item>
                                <Menu.Item
                                  className={s.ant_menu_horizontal}
                                  key="listproducts"
                                  onClick={() => history.push("/listproducts")}
                                >
                                  <img
                                    className={s.navsvg}
                                    src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/myformulations.svg"
                                  />
                                  <span>My Formulations</span>
                                </Menu.Item>

                                <Menu.Item
                                  className={s.ant_menu_horizontal}
                                  key="create"
                                  onClick={() => {
                                    {
                                      /* this.props.clearForms(); */
                                    }
                                    history.push("/product");
                                    {
                                    }
                                  }}
                                >
                                  <img
                                    className={s.navsvg}
                                    src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/createaromatherapy.svg"
                                  />
                                  <span>Aromatherapy</span>
                                </Menu.Item>

                                {showCosmeticItem ? (
                                  <Menu.Item
                                    className={s.ant_menu_horizontal}
                                    key="createcosmetic"
                                    onClick={() => {
                                      history.push("/cosmode");
                                      {
                                      }
                                    }}
                                  >
                                    <img
                                      className={s.navsvg}
                                      src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/createskincare.svg"
                                    />
                                    <span>Skincare</span>
                                  </Menu.Item>
                                ) : (
                                  <Menu.Item className={s.ant_menu_horizontal} key="createcosmetic">
                                    <img
                                      className={s.navsvg}
                                      src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/createskincare.svg"
                                    />
                                    <Tooltip
                                      placement="topRight"
                                      title={
                                        <div className={s.upgradeDiv}>
                                          <h3>Upgrade now to access Skincare, IFRA and more!</h3>
                                          <Button onClick={this.manageSubscription}>Upgrade Now</Button>
                                        </div>
                                      }
                                    >
                                      <span style={{ color: "#C6C6C6" }}>Skincare</span>
                                    </Tooltip>
                                  </Menu.Item>
                                )}
                                {showAlchemy &&
                                <Menu.Item key="playground" className={s.ant_menu_horizontal} onClick={() => history.push("/playground")}>
                                      <img
                                        className={s.navsvg}
                                        src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/aroma_alchemy.webp"
                                      />
                                      <span>Aromatic Alchemy</span>
                                    </Menu.Item>
                                }
                                <Menu.Item className={s.ant_menu_horizontal} key="inventory" onClick={() => history.push("/inventory")}>
                                  <img
                                    className={s.navsvg}
                                    src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/inventory.svg"
                                  />

                                  <span>Inventory</span>
                                </Menu.Item>
                                <SubMenu
                                  key="community"
                                  className={s.ant_menu_horizontal}
                                  title={
                                    <span className="submenu-title-wrapper">
                                      <img
                                        className={s.navsvg}
                                        src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/community.svg"
                                      />

                                      <span>Community</span>
                                    </span>
                                  }
                                >
                                  <Menu.Item
                                    key="communtiy-fb"
                                    onClick={() =>
                                      window.open("https://www.facebook.com/groups/2081197135340829/?source_id=109407770469194", "_blank")
                                    }
                                  >
                                    <span>FB Group AIB </span>
                                  </Menu.Item>
                                  <Menu.Item
                                    key="community-aib"
                                    onClick={() => window.open("https://www.facebook.com/groups/1016692035359049/", "_blank")}
                                  >
                                    <span>FB Group BP</span>
                                  </Menu.Item>
                                </SubMenu>
                                <SubMenu
                                  key="tutorials"
                                  className={s.ant_menu_horizontal}
                                  title={
                                    <span className="submenu-title-wrapper">
                                      <img
                                        className={s.navsvg}
                                        src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/tutorials.svg"
                                      />

                                      <span>Tutorials</span>
                                    </span>
                                  }
                                >
                                  <Menu.Item
                                    className={s.ant_menu_horizontal}
                                    key="tutorials-4.0"
                                    onClick={() => history.push("/tutorials")}
                                  >
                                    <span>BP 4.0 Tutorials </span>
                                  </Menu.Item>

                                  <Menu.Item
                                    className={s.ant_menu_horizontal}
                                    key="tutorials-5.0"
                                    onClick={() => history.push("/tutorials45")}
                                  >
                                    <span>BP 4.5 Tutorials </span>
                                  </Menu.Item>

                                  <Menu.Item
                                    key="tutorials-all"
                                    onClick={() => window.open("https://app.searchie.io/widget/Pm1Z07aLl2#/", "_blank")}
                                  >
                                    <span>All Tutorials</span>
                                  </Menu.Item>

                                  <Menu.Item
                                    key="tutorials-youtube"
                                    onClick={() => window.open("https://www.youtube.com/@BlendPrecisely/streams", "_blank")}
                                  >
                                    <span>YouTube</span>
                                  </Menu.Item>
                                </SubMenu>

                                <Menu.Item className={s.ant_menu_horizontal} key="feedback" onClick={() => history.push("/feedback")}>
                                  <img
                                    className={s.navsvg}
                                    src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/featurerequest.svg"
                                  />

                                  <span>Feature Request</span>
                                </Menu.Item>

                                <Menu.Item className={s.ant_menu_horizontal} key="resources" onClick={() => history.push("/resources")}>
                                  <img
                                    className={s.navsvg}
                                    src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/resources.svg"
                                  />

                                  <span>Resources</span>
                                </Menu.Item>

                                <Menu.Item key="about" className={s.ant_menu_horizontal} onClick={() => history.push("/about")}>
                                  <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/about.svg" />

                                  <span>About</span>
                                </Menu.Item>
                                {showCosmeticItem ? (
                                  <Menu.Item key="ai" className={s.ant_menu_horizontal} onClick={() => history.push("/ai")}>
                                    <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/ai.svg" />

                                    <span>AI Prompts</span>
                                  </Menu.Item>
                                ) : (
                                  <Menu.Item className={s.disabled} key="ai" className={s.ant_menu_horizontal}>
                                    <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/ai.svg" />
                                    <Tooltip
                                      placement="topRight"
                                      title={
                                        <div className={s.upgradeDiv}>
                                          <h3>Upgrade now to access AI Prompts </h3>
                                          <Button onClick={this.manageSubscription}>Upgrade Now</Button>
                                        </div>
                                      }
                                    >
                                      <span style={{ color: "#C6C6C6" }}>AI Prompts</span>
                                    </Tooltip>
                                  </Menu.Item>
                                )}

                                {this.props.user.is_staff && (
                                  <Menu.Item key="aitest" className={s.ant_menu_horizontal} onClick={() => history.push("/aitest")}>
                                    <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/ai.svg" />

                                    <span>AI Test</span>
                                  </Menu.Item>
                                )}

                                {showAIItem && (
                                  <Menu.Item key="chatbot" className={s.ant_menu_horizontal} onClick={() => history.push("/chatbot")}>
                                    <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/chat.webp" />
                                    <span>BP Assistant</span>
                                  </Menu.Item>
                                )}
                                {/* <Menu.Item key="calc" className={s.ant_menu_horizontal} onClick={() => history.push("/calc")}> */}
                                {/*   <img className={s.navsvg} src="https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/ai.svg" /> */}

                                {/*   <span>Calculators</span> */}
                                {/* </Menu.Item> */}
                              </Menu>
                            </>
                          )}
                        </Sider>
                        <Header toggleSider={this.toggle} />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
            <Layout.Content className={content_class}>{this.props.children}</Layout.Content>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

const mapState = (state) => ({
  user: state.user.user,
  currencies: state.global.currencies,
  currentRouteName: state.global.currentRouteName,
});

const mapDispatch = {
  clearForms,
  handleStripePortalRedirect,
};

export default connect(mapState, mapDispatch)(withStyles(antStyles, paginationStyles, globalStyles, s)(AppLayout));
